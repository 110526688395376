const configs = {
  arica: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de Arica',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/bg-arica.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismoarica',
  },
  putre: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de Putre',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/putre.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismoputre',
  },

  ayp: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de Arica y Parinacota',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/ayp.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismoaricayparinacota',
  },

  generallagos: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de General Lagos',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/general-lagos.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismograllagos',
  },
  camarones: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Datos de Camarones',
    subtitle:
      'Proyecto financiado a través del Fondo de Innovación para Competitividad del Gobierno Regional de Arica y Parinacota y su Consejo Regional enmarcada en la Estratégica Regional de Innovación',
    backgroundImage: require('./components/assets/images/camarones.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
    hashtag: '#turismocamarones',
  },
  site2: {
    logo: require('./components/assets/images/logo.png'),
    title: 'Título del Sitio 2',
    subtitle: 'Subtítulo del Sitio 2',
    backgroundImage: require('./components/assets/images/bg-arica.png'),
    mapCenter: { lat: -18.4782534, lng: -70.3125988 },
  },
};

const currentSite = process.env.REACT_APP_SITE || 'site1';
const config = configs[currentSite];

export default config;
