import React, { useContext, useState, useEffect } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { MapContext } from '../../../../context/MapContext';
import config from '../../../../config';
import Chat from '../Chat/Chat';
import { MapWrapper, MapContainer } from './MapStyles';
import useGoogleMapsApiKey from '../../../../hooks/maps/useGoogleMapsApiKey';
import useFetchLocations from '../../../../hooks/maps/useFetchLocations';
import PropTypes from 'prop-types';

const Map = () => {
  const { points, setPoints } = useContext(MapContext);
  const { data, loading, error, fetchLocations } = useFetchLocations();
  const apiKey = useGoogleMapsApiKey();
  const [selectedPoint, setSelectedPoint] = useState(null);

  // Cargar puntos solo al inicio del componente
  useEffect(() => {
    fetchLocations(); // Llama solo una vez
  }, []); // Dependencias vacías para evitar llamadas repetidas

  // Actualizar puntos en el contexto cuando los datos estén disponibles
  useEffect(() => {
    if (data) {
      setPoints(data);
    }
  }, [data, setPoints]);

  // Filtrar y convertir puntos a formato válido para Google Maps
  const pointsWithNumbers = points
    .filter((point) => point.latitude && point.longitude)
    .map((point) => ({
      ...point,
      latitude: parseFloat(point.latitude),
      longitude: parseFloat(point.longitude),
    }));

  return (
    <MapWrapper>
      <MapContainer>
        <Chat />
        {loading && <div>Cargando puntos en el mapa...</div>}
        {error && <div style={{ color: 'red' }}>Error: {error.message}</div>}
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={config.mapCenter}
            zoom={15}
            onClick={() => setSelectedPoint(null)}
          >
            {pointsWithNumbers.map((point, index) => (
              <Marker
                key={index}
                position={{ lat: point.latitude, lng: point.longitude }}
                onClick={() => setSelectedPoint(point)}
              />
            ))}
            {selectedPoint && (
              <InfoWindow
                position={{
                  lat: selectedPoint.latitude,
                  lng: selectedPoint.longitude,
                }}
                onCloseClick={() => setSelectedPoint(null)}
              >
                <div>
                  <div>{selectedPoint.name}</div>
                  <div>Dirección: {selectedPoint.address}</div>
                  <div>Email: {selectedPoint.email}</div>
                  <div>Teléfono: {selectedPoint.phone}</div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </MapWrapper>
  );
};

Map.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Map;
