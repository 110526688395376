import styled from 'styled-components';

export const SectionContainer = styled.section`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 4rem;
  margin-bottom: 1.5rem;

  @media (max-width: 1024px) and (min-width: 992px) {
    padding: 0 2rem;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 600px));
  gap: 1rem;
  width: 100%;
  justify-content: center;

  @media (max-width: 1024px) and (min-width: 992px) {
    gap: 0.2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 769px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1290px) {
    grid-template-columns: repeat(8, 1fr);
  }



  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
