import React, { useEffect, useState } from 'react';
import {
  SectionContainer,
  ContentWrapper,
  CardsWrapper,
} from './StatisticsSectionStyles';
import StatisticCard from '../StatisticsCard/StatisticsCard';
import SectionTitle from '../StatisticsSectionTitle/StatisticsSectionTitle';

const StatisticsSection = ({ onStatClick }) => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const endpoint = process.env.REACT_APP_API_ENDPOINT + 'api/location-stats';
  const token = process.env.REACT_APP_API_TOKEN;

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });
        if (!response.ok) {
          throw new Error(`Error en la respuesta: ${response.statusText}`);
        }
        const data = await response.json();
        setStatistics(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [endpoint, token]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Manejador de clic para los cuadros
  const handleStatClick = (tipo) => {
    onStatClick(tipo); // Llama a la función que actualiza el mapa
  };

  return (
    <SectionContainer>
      <ContentWrapper>
        <SectionTitle>Estadísticas Zona</SectionTitle>
        <CardsWrapper>
          {statistics.map((stat, index) => (
            <StatisticCard
              key={index}
              title={stat.tipo}
              data={stat.cantidad}
              onClick={() => handleStatClick(stat.tipo)} // Manejador de clic
            />
          ))}
        </CardsWrapper>
      </ContentWrapper>
    </SectionContainer>
  );
};

export default StatisticsSection;
